@import url('https://fonts.googleapis.com/css2?family=Nunito&family=Overpass&display=swap');

.title {
  font-size: 3rem;
  font-weight: bold;
  color: #000;
  position: relative;
  top: 100px;
  width: 100%;
  text-align: center;
  font-family: 'Manrope', sans-serif;
}

.title span {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid black;
}

.form {
    padding: 1rem;
    width: 50%;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    left: 25%;
    margin-top: 170px;
    border: 1px solid black;
    font-family: 'Manrope', sans-serif;
  }
  
  .control {
    margin-bottom: 0.5rem;
  }
  
  .control label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .control input,
  .control textarea {
    display: block;
    font: inherit;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 0.25rem;
    resize: none;
    width: 100%;
  }

  .control textarea {
    height: 150px;
    min-width: 100%;
    max-width: 100%;
    max-height: 150px;
  }
  
  .actions {
    margin-top: 1rem;
    text-align: center;
    font-family: 'Manrope', sans-serif;
  }
  
  .actions button {
    font: inherit;
    cursor: pointer;
    background-color: black;
    color: white;
    padding: 0.5rem 1.5rem;
    border: 1px solid black;
    border-radius: 4px;
    font-weight: bold;
  }
  
  .actions button:hover,
  .actions button:active {
    color: gray;
  }