.header {
    width: 100%;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    padding: 0 10%;
  }
  
  .logo {
    font-size: 2rem;
    color: white;
    font-weight: bold;
  }
  
  .header ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: baseline;
  }
  
  .header li {
    margin-left: 3rem;
  }
  
  .header a {
    text-decoration: none;
    font-size: 1.5rem;
    color: white;
  }
  
  .header a:hover,
  .header a:active,
  .header a.active {
    color: gray;
  }
  
  .badge {
    background-color: #cc2062;
    color: white;
    border-radius: 12px;
    padding: 0 1rem;
    margin-left: 0.5rem;
  }


  .github, .linkedin {
    margin-left:10px;
  }