.iconContainer {
    width: 50%;
    font-size: 4rem;
    position: relative;
    left: 25%;
}

.github {
    color: black;
}

.linkedin {
    color: black;
    margin-left: 15px;
}

.iconContainer .github:hover{
    color: gray;
    cursor:pointer;
    background-color: white;
    border-radius: 5px;
}

.iconContainer .linkedin:hover{
    color: rgb(14, 118, 168);
    cursor:pointer;
    margin-left: 15px;
    background-color: white;
    border-radius: 5px;
}
